<template>
  <div class="mobile-container" v-if="userInfo.UserIsCz == 1">
    <div class="status-bar-item">
      <div class="left-btn img-cover" @click="$router.go(-1)">
        <img src="../../assets/images/back-icon-white.png" />
      </div>
      <div class="title-item">余额充值</div>
    </div>
    <div class="page-bg-item img-cover"><img src="../../assets/images/balance-bg.png" /></div>
    <div class="page-content-item">
      <div class="balance-item">
        <div class="name">我的余额</div>
        <div class="money">{{ Math.round(userInfo.Balance * 100000) / 100000 }}</div>
        <router-link class="btn" :to="{path: '/rechargeRecord'}">充值记录 <img src="../../assets/images/right-blue-icon.png" /></router-link>
      </div>
      <div class="title-item">充值金额</div>
      <div class="input-item">
        <input v-model="money" :type="selectedObj.Id != -1 ? 'number' : 'text'" :placeholder="selectedObj && selectedObj.Id == -1 ? '请输入充值卡密' : '请输入充值金额'" />
      </div>
      <div v-if="selectedObj && selectedObj.Id != -1" class="tip-item">{{ ('充值金额' + (selectedObj.Min ? '最低' + selectedObj.Min : '') + (selectedObj.Max ? '最高' + selectedObj.Max : '')) }}</div>
      <div class="title-item">请选择支付方式</div>
      <div class="pay-types">
        <div class="item" v-for="(item, index) in paymentList" :key="index" @click="handleChoosePayment(item)">
          <div class="icon img-cover">
            <img v-if="item.Paytype == 1" src="../../assets/images/alipay-icon.png" />
            <img v-if="item.Paytype == 2" src="../../assets/images/qq-icon.png" />
            <img v-if="item.Paytype == 3" src="../../assets/images/wechat-icon.png" />
          </div>
          <div class="name">{{ item.Payname }} <span v-if="item.Sxf">({{ item.Sxf }}%)</span></div>
          <div class="select img-cover" :class="selectedObj && selectedObj.Id == item.Id ? 'active' : ''"><img v-if="selectedObj && selectedObj.Id == item.Id ? 'active' : ''" src="../../assets/images/selected-icon.png" /></div>
        </div>
        <div v-if="showKm" class="item" @click="handleChoosePayment({Id: -1})">
          <div class="icon img-cover"><img src="../../assets/images/km-icon.png" /></div>
          <div class="name">卡密支付</div>
          <div class="select img-cover" :class="selectedObj && selectedObj.Id == -1 ? 'active' : ''"><img v-if="selectedObj && selectedObj.Id == -1 ? 'active' : ''" src="../../assets/images/selected-icon.png" /></div>
        </div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="btn-item public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>充值</span>
    </div>
    <div class="cz-tip">{{ czTip ? czTip : '有问题请联系客服' }}</div>
    <van-popup v-model="showQRCode" :round="true" position="bottom" @close="handleClosePopup">
      <div class="popup">
        <div class="title">付款码</div>
        <div class="img img-contain" ref="qrcode"></div>
        <div class="btn-item public-btn" @click="handleClosePopup">确定</div>
      </div>
    </van-popup>
    <van-popup v-model="sdPayPopup.show" :round="true" position="bottom" @close="handleCloseSdPayPopup">
      <div class="popup">
        <div class="money">¥ <span>{{ sdPayPopup.money }}</span></div>
        <div class="title">付款码</div>
        <div class="img img-contain"><img :src="sdPayPopup.img" /></div>
        <div class="title">识别码(点击识别码复制)</div>
        <div class="code" @click="handleCopy(sdPayPopup.remark)">{{ sdPayPopup.remark }}</div>
        <div class="btn-item public-btn" @click="handleCloseSdPayPopup">确定</div>
      </div>
    </van-popup> 
    <van-popup v-model="showSuccess" :round="true" position="center">
      <div class="success-popup">
        <div class="icon img-cover"><img src="../../assets/images/success-icon.png"></div>
        <div class="title">充值成功</div>
        <div class="btn public-btn" @click="showSuccess = false">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapState, mapActions } from 'vuex'

export default {
  name: "Balance",
  components: {

  },
  data() {
    return {
      showKm: false,
      czTip: '',
      submiting: false,
      money: '',
      paymentList: [],
      selectedObj: {},
      dmfTimer: null,
      showQRCode: false,
      sdPayPopup: {
        show: false,
        img: '',
        remark: '',
        money: ''
      },
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {

  },
  mounted() {
    this.getSetting()
    this.handleGetPayment()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleChoosePayment(item) {
      this.selectedObj = item
    },
    async getSetting(){
      var res = await this.$API.getSets.post({
        Key: 'cz_remind,is_km'
      })
      this.czTip = res.cz_remind
      this.showKm = res.is_km == 1
    },
    async handleGetPayment() {
      const { userInfo } = this
      var res = await this.$API.payment.post()
      if (res.info && res.info.length > 0) {
        const list = []
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index];
          const isLevel = item.IsLevel ? item.IsLevel.split(',') : []
          if (isLevel.length == 0 || isLevel.includes(`${userInfo.Level}`)) {
            list.push(item)
          }
        }
        if (list.length > 0) {
          this.paymentList = list
          this.selectedObj = list[0]
        }
      }
    },
    async handleSubmit() {
      const { money, selectedObj, submiting } = this
      if (submiting) {
        return
      }
      if (!money && selectedObj.Id == -1) {
        this.$toast('请输入充值卡密')
        return
      }
      if (!money) {
        this.$toast('请输入充值金额')
        return
      }
      if (parseFloat(money) > parseFloat(selectedObj.Max)) {
        this.$toast('充值金额最高' + selectedObj.Max)
        return
      }
      if (parseFloat(money) < parseFloat(selectedObj.Min)) {
        this.$toast('充值金额最低' + selectedObj.Min)
        return
      }
      const params = {}
      let remark = ''
      if (selectedObj.Id == -1) {
        params.Code = money
        params.Switch = 1
      } else {
        params.Money = money
        params.PaymentId = selectedObj.Id
        params.PayType = selectedObj.Paytype
        if (selectedObj.Type == 1) {
          params.Switch = 2
        }
        if (selectedObj.Type == 2) {
          params.Switch = 3
          remark = this.$TOOL.generateRandomNumber(8)
          params.Remark = remark
        }
        if (selectedObj.Type == 3 || selectedObj.Type == 4) {
          params.Switch = 4
        }
        if (selectedObj.Type == 5) {
          params.Switch = 5
        }
      }
      this.submiting = true
      var res = await this.$API.pay.post(params)
      this.submiting = false
      if (res.error == 0) {
        if (selectedObj.Type == 3 || selectedObj.Type == 1) {
          window.location.href = res.info
        } else if (selectedObj.Type == 5 || selectedObj.Type == 4) {
          this.handleSetPopup(res.info, res.orderNo || '')
        } else if (selectedObj.Type == 2) {
          this.sdPayPopup = {
            show: true,
            img: selectedObj.Vendorid,
            remark,
            money
          }
        } else {
          this.showSuccess = true
          this.GetUserInfo()
        }
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    handleSetPopup(url, OrderNo) {
      this.showQRCode = true
      setTimeout(() => {
        this.$refs.qrcode.innerHTML = ''
        new QRCode(this.$refs.qrcode, {
          text: url,
          width: 240,
          height: 240,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }, 1000);
      if (OrderNo) {
        if (this.dmfTimer) {
          clearInterval(this.dmfTimer)
        }
        this.dmfTimer = setInterval(async () => {
          var res = await this.$API.reOrder.post({
            OrderNo: OrderNo
          })
          if (res.info.Status != 2) {
            this.handleClosePopup()
            this.showSuccess = true
            // this.$router.push({path: '/success'})
          }
        }, 1000)
      }
    },
    handleCloseSdPayPopup() {
      this.sdPayPopup.show = false
      this.GetUserInfo()
      this.$dialog.alert({
        type: 'danger',
        title: '提示',
        message: '确认成功，请及时联系客服充值',
        confirmButtonText: '确定',
      })
    },
    handleClosePopup() {
      this.showQRCode = false
      this.GetUserInfo()
      if (this.dmfTimer) {
        clearInterval(this.dmfTimer)
        this.dmfTimer = null
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
}
.status-bar-item {
  height: .88rem;
  position: fixed;
  top: 0;
  width: var(--max-width);
  left: 50%;
  margin-left: var(---max-width-50);
  background-color: #1399ff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .title-item {
    font-weight: bold;
    font-size: .32rem;
    color: #fff;
  }
  .left-btn {
    cursor: pointer;
    position: absolute;
    left: .14rem;
    top: .14rem;
    width: .6rem;
    height: .6rem;
  }
}
.page-bg-item {
  margin-top: -.88rem;
  width: 100%;
  height: 3.28rem;
}
.page-content-item {
  width: 100%;
  box-sizing: border-box;
  margin-top: -1.04rem;
  position: relative;
  padding: 0 .24rem;
  .balance-item {
    position: relative;
    border: .02rem solid #fff;
    border-radius: .24rem;
    height: 1.6rem;
    padding-left: .32rem;
    background: linear-gradient(180deg, rgba(88, 183, 255, 1) 0%, #fff 100%);
    color: #1399ff;
    .name {
      margin-top: .36rem;
      line-height: .34rem;
      font-size: .24rem;
    }
    .money {
      font-weight: bold;
      line-height: .58rem;
      font-size: .48rem;
    }
    .btn {
      position: absolute;
      top: .56rem;
      right: 0;
      width: 1.7rem;
      height: .52rem;
      border-radius: .26rem 0 0 .26rem;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: .04rem;
      font-size: .24rem;
      font-weight: 500;
      img {
        width: .32rem;
        height: .32rem;
      }
    }
  }
  .title-item {
    margin-top: .32rem;
    margin-left: .08rem;
    font-size: .3rem;
    color: #11113a;
    font-weight: bold;
    line-height: .42rem;
  }
  .input-item {
    margin-top: .24rem;
    padding: 0 .24rem;
    height: .88rem;
    background-color: #fff;
    border-radius: .16rem;
  }
  .tip-item {
    margin-top: .24rem;
    font-size: .24rem;
    color: #ff2442;
    line-height: .34rem;
  }
  .pay-types {
    margin-top: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    padding: 0 .24rem;
    .item {
      cursor: pointer;
      height: .88rem;
      border-bottom: .02rem solid #f5f5fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .icon {
        width: .48rem;
        height: .48rem;
      }
      .name {
        margin-left: .48rem;
        flex: 1;
        font-size: .28rem;
        color: #030304;
      }
      .select {
        width: .36rem;
        height: .36rem;
        box-sizing: border-box;
        border: .04rem solid #ccc;
        border-radius: 50%;
        &.active {
          border: none;
        }
      }
    }
  }
}
.btn-item {
  cursor: pointer;
  margin-top: .32rem;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  color: #fff;
  font-size: .3rem;
  font-weight: bold;
  line-height: .88rem;
  text-align: center;
}
.cz-tip {
  margin-top: .32rem;
  margin-bottom: .64rem;
  font-size: .24rem;
  line-height: .34rem;
  text-align: center;
  color: #b3b3b3;
  white-space: pre-wrap;
}
/deep/.van-popup.van-popup--bottom {
  background-color: transparent;
  width: var(--max-width) !important;
  left: 50%;
  margin-left: var(---max-width-50);
}
.popup {
  width: 7.5rem;
  height: 7.8rem;
  box-sizing: border-box;
  border-radius: .32rem .32rem 0 0;
  background-color: #fff;
  padding: .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .money {
    font-size: .28rem;
    font-weight: bold;
    color: #222;
    margin-bottom: -.2rem;
    span {
      font-size: .54rem;
    }
  }
  .code {
    font-size: .3rem;
    color: #333;
    line-height: .42rem;
    margin-bottom: .2rem;
  }
  .title {
    padding-top: .2rem;
    font-size: .32rem;
    font-weight: bold;
    color: #333;
    line-height: .48rem;
  }
  .img {
    width: 3.2rem;
    height: 3.2rem;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
.success-popup {
  background-color: #fff;
  border-radius: .2rem;
  padding: .4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }
  .title {
    margin-top: .48rem;
    color: #030304;
    font-size: .32rem;
    font-weight: 500;
  }
  .btn {
    margin-top: 10px;
    width: 4.8rem;
    height: .8rem;
    border-radius: .12rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .24rem;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>